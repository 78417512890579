<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile">
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <div class="columns is-gapless">
            <div class="column">
              <p class="title">Detail Info
                <span v-show="!innerValue.isNew"
                  class="icon has-text-info tooltip is-tooltip-info"
                  :class="[ canChangeReadOnly ? 'readonly-pointer' : '' ]"
                  :data-tooltip="readOnlyView ? 'Invoice is locked' : 'Invoice is unlocked'"
                  @click="toogleReadOnly()">
                  <i class="icon mdi"
                    :class="[ readOnlyView ? 'mdi-lock-outline' : 'mdi-lock-open-outline' ]" />
                </span>
              </p>
              <p v-if="innerValue.gstPaidDate"
                class="subtitle has-text-warning">Only Reference &amp; Remarks can be updated</p>
            </div>
            <div class="column is-narrow">
              <span v-if="innerValue.gstPaidDate"
                class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
                data-tooltip="GST has been paid. Only Reference &amp; Remarks can be updated">GST paid on {{ $filters.formatDateLocale(innerValue.gstPaidDate, $userInfo.locale) }}</span>
            </div>
          </div>
          <div class="field-body">
            <div class="field">
              <label class="label">Invoice No.</label>
              <div class="control">
                <input class="input is-static"
                  :value="`${innerValue.prefix}${innerValue.invoiceNo}${innerValue.suffix}`"
                  type="text"
                  placeholder="Invoice No."
                  readonly>
              </div>
            </div>
            <div v-if="gstPaid || readOnlyView"
              class="field">
              <label class="label">Invoice Date</label>
              <input class="input is-static"
                :value="$filters.formatDateLocale(selectedInvoiceDate, $userInfo.locale)"
                readonly>
            </div>
            <div v-else-if="$userInfo && !gstPaid && selectedInvoiceDate"
              class="field is-narrow">
              <label class="label">Invoice Date</label>
              <v-date-picker v-model="selectedInvoiceDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                :min-date="firstDayOfMonth"
                @input="invoiceDateInputEvent"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Invoice Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="is-divider" />
          <div v-if="!gstPaid && !readOnlyView"
            class="field">
            <div class="control is-pulled-right">
              <label for="chk-pretty-customer"
                style="font-size: 0.75rem">Customer</label>
              <div class="pretty p-icon p-round p-smooth mr-0 ml-1"
                style="font-size: 0.875rem">
                <input id="chk-pretty-customer"
                  name="chk-pretty-customer"
                  v-model="assetFilter.showCustomer"
                  type="checkbox">
                <div class="state p-success">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>
              <label for="chk-pretty-insurer"
                class="pl-2"
                style="font-size: 0.75rem">Insurer</label>
              <div class="pretty p-icon p-round p-smooth mr-0 ml-1"
                style="font-size: 0.875rem">
                <input id="chk-pretty-insurer"
                  name="chk-pretty-insurer"
                  v-model="assetFilter.showInsurer"
                  type="checkbox">
                <div class="state p-info">
                  <i class="icon mdi mdi-check" />
                  <label />
                </div>
              </div>

              <!-- <input class="is-checkradio is-small is-circle is-success is-rtl"
                :class="{ 'has-background-color': assetFilter.showCustomer }"
                id="chk-customer"
                name="chk-customer"
                type="checkbox"
                v-model="assetFilter.showCustomer">
              <label for="chk-customer">Customer</label> -->
              <!-- <input class="is-checkradio is-small is-circle is-info is-rtl"
                :class="{ 'has-background-color': assetFilter.showInsurer }"
                id="chk-insurer"
                name="chk-insurer"
                type="checkbox"
                v-model="assetFilter.showInsurer">
              <label for="chk-insurer">Insurer</label> -->
            </div>
          </div>
          <div class="field">
            <label class="label">Payer</label>
            <div v-if="(gstPaid || readOnlyView) && selectedAsset"
              class="field">
              <input class="input is-static"
                :value="selectedAsset.assetName"
                readonly>
            </div>
            <div v-else-if="!gstPaid && !readOnlyView"
              class="field has-addons">
              <div class="control is-expanded">
                <multiselect v-if="activeCustomers"
                  :class="{ 'is-danger' : $v.entity.assetId.$error }"
                  v-model="selectedAsset"
                  deselect-label="Can't remove this value"
                  track-by="assetId"
                  label="assetName"
                  placeholder="Select a payer"
                  :options="activeCustomers"
                  :loading="isCustomerLoading"
                  :internal-search="false"
                  :options-limit="assetFilter.pageSize"
                  @select="selectAsset"
                  @remove="removeAsset"
                  @search-change="getAssetDropdownFilter"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true">
                  <template slot="option"
                    slot-scope="props">
                    <span class="tag is-pulled-right"
                      :class="{'is-success' : props.option.assetType == 1, 'is-info' : props.option.assetType == 4}">
                      {{ props.option.assetType == 1 ? 'Customer' : 'Insurer' }}
                    </span>
                    <span>{{ `${props.option.assetName}` }}</span>
                  </template>
                  <span class="has-text-danger"
                    slot="noResult">Payer not found.</span>
                </multiselect>
                <span class="help is-danger"
                  v-if="selectedAsset && !selectedAsset.active">Payer has been set to inactive.</span>
                <span class="help is-danger"
                  v-if="$v.entity && !$v.entity.assetId.validGuid">Payer is required.</span>
              </div>
              <div class="control">
                <a class="button"
                  :class="assetButtonClass"
                  @click="onEditAddAsset()"
                  :disabled="gstPaid || readOnlyView">
                  <span class="icon">
                    <i class="mdi mdi-24px"
                      :class="assetButtonIconClass" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Contact</label>
            <div v-if="gstPaid || readOnlyView"
              class="field">
              <input class="input is-static"
                :value="selectedContact ? selectedContact.fullname : ''"
                readonly>
            </div>
            <div v-else
              class="field has-addons">
              <div class="control is-expanded">
                <multiselect v-model="selectedContact"
                  track-by="contactId"
                  label="fullname"
                  placeholder="Select a contact"
                  :options="activeContacts"
                  :searchable="true"
                  :show-labels="false"
                  :allow-empty="true"
                  @select="selectContact"
                  @remove="removeContact"
                  @search-change="onContactSearchChange"
                  @input.native="captureContactInput">
                  <span class="has-text-danger"
                    slot="noResult">Contact not found.</span>
                </multiselect>
                <span class="help is-danger"
                  v-if="selectedContact && !selectedContact.active">Contact has been set to inactive.</span>
              </div>
              <div class="control">
                <a class="button"
                  :class="contactButtonClass"
                  @click="onEditAddContact()"
                  :disabled="!hasAssetSelected || gstPaid || readOnlyView">
                  <span class="icon">
                    <i class="mdi mdi-24px"
                      :class="contactButtonIconClass" />
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="pretty p-icon p-curve p-smooth p-bigger">
            <input id="chk-invoice-exported"
              name="chk-invoice-exported"
              v-model="innerValue.exported"
              type="checkbox"
              :disabled="readOnlyView || !$userInfo.isSupportUser">
            <div class="state p-primary">
              <i class="icon mdi mdi-check" />
              <label>Exported</label>
            </div>
          </div>
          <!-- <div v-if="$userInfo.isSupportUser"
            class="field">
            <div class="control">
              <input type="checkbox"
                v-model="innerValue.exported"
                class="is-checkradio"
                id="invoice-exported"
                :disabled="readOnlyView">
              <label for="invoice-exported">Exported</label>
            </div>
          </div> -->
          <div class="is-divider" />
          <label class="label">Items</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <tr>
                <th>Description</th>
                <th class="has-text-right">
                  Total
                </th>
              </tr>
            </thead>
            <tfoot class="has-multiple-totals">
              <tr>
                <th class="has-text-right">Total excl. GST</th>
                <th class="has-text-right has-text-success">{{ innerValue.totalExGst | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right">GST</th>
                <th class="has-text-right has-text-success">{{ innerValue.gst | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right">Total incl. GST</th>
                <th class="has-text-right has-text-success">{{ innerValue.totalIncGst | formatCurrency($userInfo.locale) }}</th>
              </tr>
            </tfoot>
            <tbody v-if="activeItems.length > 0">
              <tr v-for="(item) in activeItems"
                :key="item.invoiceItemId">
                <td>{{ item.invoiceItemDesc }}</td>
                <td class="has-text-right">{{ item.totalIncGst | formatNumber($userInfo.locale) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="3">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <label class="label">Payments</label>
          <table class="table is-bordered is-striped is-narrow is-fullwidth">
            <thead>
              <th>Date</th>
              <th>Cheque No.</th>
              <th class="has-text-right">Paid</th>
            </thead>
            <tfoot v-if="innerValue.payments.length > 0"
              class="has-multiple-totals">
              <tr>
                <th class="has-text-right"
                  colspan="2">Total Paid</th>
                <th class="has-text-right has-text-success">{{ totalPaid | formatCurrency($userInfo.locale) }}</th>
              </tr>
              <tr>
                <th class="has-text-right"
                  colspan="2">Balance</th>
                <th class="has-text-right">
                  <span :class="balanceClass">{{ balance | formatCurrency($userInfo.locale) }}</span>
                </th>
              </tr>
            </tfoot>
            <tbody v-if="innerValue.payments.length > 0">
              <tr v-for="(payment) in innerValue.payments"
                :key="payment.invoicePaymentId">
                <td>
                  {{ $filters.formatDateLocale(payment.payDate, $userInfo.locale) }}
                </td>
                <td>{{ payment.chequeNo }}</td>
                <td class="has-text-right">{{ payment.amountPaid | formatNumber($userInfo.locale) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td class="has-text-centered"
                  colspan="3">
                  <span icon="icon is-medium">
                    <i class="mdi mdi-36px mdi-dots-horizontal mdi-dark mdi-inactive" />
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </article>
      </div>
      <div class="tile is-parent is-half">
        <article class="tile is-child box detail-page-tile">
          <p class="title">Reference &amp; Remarks</p>
          <div class="field">
            <label class="label">Reference</label>
            <div class="control">
              <textarea v-if="!readOnlyView"
                class="textarea"
                v-model="innerValue.reference"
                placeholder=""
                :disabled="readOnlyView" />
              <span v-else>{{ innerValue.reference }}</span>
            </div>
          </div>
          <div v-if="!readOnlyView"
            class="field">
            <div class="control is-pulled-right">
              <div class="is-pulled-right">
                <a class="button is-small is-success tooltip"
                  data-tooltip="Add Remarks"
                  @click="toggleRemarkModal()">
                  <span class="icon">
                    <i class="mdi mdi-18px mdi-plus" />
                  </span>
                  <span>Remarks</span>
                </a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Remarks</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead />
              <tbody v-if="activeRemarks.length > 0">
                <tr v-for="(remark, index) in activeRemarks"
                  :key="remark.remarkId">
                  <td class="is-content-width">
                    <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </td>
                  <td v-if="readOnlyView">
                    <div class="control">
                      <span>{{ remark.remarks }}</span>
                    </div>
                  </td>
                  <td v-else>
                    <div class="control">
                      <textarea class="textarea"
                        v-model="remark.remarks"
                        placeholder="" />
                    </div>
                  </td>
                  <td v-if="!readOnlyView"
                    class="has-text-centered is-content-width has-vertical-middle">
                    <a class="button is-danger is-small is-inverted"
                      @click="deleteRemark(remark, index, remarkTypes.Remark)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="has-text-centered"
                    colspan="2">
                    <span icon="icon is-medium">
                      <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!readOnlyView"
            class="field">
            <div class="control is-pulled-right">
              <div class="is-pulled-right">
                <a class="button is-small is-success tooltip"
                  data-tooltip="Add Remarks"
                  @click="toggleRemarkModal()">
                  <span class="icon">
                    <i class="mdi mdi-18px mdi-plus" />
                  </span>
                  <span>Remarks</span>
                </a>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Printed Remarks</label>
            <table class="table is-bordered is-striped is-narrow is-fullwidth">
              <thead />
              <tbody v-if="activePrintedRemarks.length > 0">
                <tr v-for="(remark, index) in activePrintedRemarks"
                  :key="remark.remarkId">
                  <td class="is-content-width">
                    <span class="tag is-info">{{ $filters.formatDateTimeLocale(remark.remarkDateTime, $userInfo.locale) }}</span>
                  </td>
                  <td v-if="readOnlyView">
                    <div class="control">
                      <span>{{ remark.remarks }}</span>
                    </div>
                  </td>
                  <td v-else>
                    <div class="control">
                      <textarea class="textarea"
                        v-model="remark.remarks"
                        placeholder="" />
                    </div>
                  </td>
                  <td v-if="!readOnlyView"
                    class="has-text-centered is-content-width has-vertical-middle">
                    <a class="button is-danger is-small is-inverted"
                      @click="deleteRemark(remark, index, remarkTypes.PrintedRemark)">
                      <span class="icon is-medium">
                        <i class="mdi mdi-delete mdi-24px" />
                      </span>
                    </a>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="has-text-centered"
                    colspan="2">
                    <span icon="icon is-medium">
                      <i class="mdi mdi-36px mdi-comment-text-outline mdi-dark mdi-inactive" />
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </div>
    <remark-modal v-if="value && isRemarkModalActive"
      :show-remark="true"
      :show-printed-remark="true"
      :show-recurring-remark="true"
      :active.sync="isRemarkModalActive"
      @add="addRemarks"
      @cancel="toggleRemarkModal()">
      <p slot="text-title">Add Quick Invoice Remarks</p>
    </remark-modal>
  </div>
</template>

<script>
import QuickInvoiceService from './QuickInvoiceService'
import Multiselect from 'vue-multiselect'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import { RemarkTypes, AssetTypes, EventHubTypes } from '@/enums'
import { RemarkModal } from '@/components/BulmaModal'
import StoreMixin from './storeMixin'
import { ContactPayload } from '@/classes'
import { getCurrencySymbol } from '@/components/currency'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { RemarkModel } from '@/classes/viewmodels'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'
import _tap from 'lodash/tap'

export default {
  name: 'QuickinvoiceDetail',
  inject: ['$vv'],
  components: {
    Multiselect,
    RemarkModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  props: {
    value: null,
    isNew: Boolean,
    gstPaid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: _cloneDeep(this.value),
      // invoiceDate: null,
      selectedAsset: null,
      selectedContact: null,
      customers: [],
      contacts: [],
      isCustomerLoading: false,
      isContactLoading: false,
      customerInput: '',
      contactInput: '',
      isRemarkModalActive: false,
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      moneyMask: {
        precision: 2,
        prefix: '$ '
      },
      assetButtonLoading: false,
      contactButtonLoading: false,
      selectedInvoiceDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    local: {
      get() {
        return this.value
      },
      set() {
        this.$emit('input', this.local)
      }
    },
    hasAssetSelected() {
      return this.selectedAsset && !_isEmpty(this.selectedAsset)
    },
    assetButtonIconClass() {
      return {
        'mdi-pencil': this.hasAssetSelected,
        'mdi-plus': !this.hasAssetSelected
      }
    },
    assetButtonClass() {
      return {
        'is-primary': this.hasAssetSelected,
        'is-success': !this.hasAssetSelected,
        'is-loading': this.assetButtonLoading
      }
    },
    hasContactSelected() {
      return this.selectedContact && !_isEmpty(this.selectedContact)
    },
    contactButtonIconClass() {
      return {
        'mdi-pencil': this.hasContactSelected,
        'mdi-plus': !this.hasContactSelected
      }
    },
    contactButtonClass() {
      return {
        'is-primary': this.hasContactSelected,
        'is-success': !this.hasContactSelected,
        'is-loading': this.contactButtonLoading
      }
    },
    activeRemarks() {
      return this.innerValue.remarks.filter((r) => !r.isDeleted)
    },
    activePrintedRemarks() {
      return this.innerValue.printedRemarks.filter((r) => !r.isDeleted)
    },
    remarkTypes() {
      return RemarkTypes
    },
    activeItems() {
      return this.innerValue.items.filter((i) => !i.isDeleted)
    },
    showAsset() {
      return {
        customer: this.assetFilter.showCustomer,
        insurer: this.assetFilter.showInsurer
      }
    },
    $v() {
      return this.$vv || this.$v
    },
    currencySymbol() {
      return getCurrencySymbol(this.$userInfo.locale)
    },
    totalPaid() {
      return roundAwayFromZero(
        this.innerValue.payments.reduce(function (total, payment) {
          return total + payment.amountPaid
        }, 0),
        2
      )
    },
    balance() {
      return this.innerValue.totalIncGst - this.totalPaid
    },
    balanceClass() {
      if (this.balance > 0) {
        return 'has-text-success'
      } else if (this.balance < 0) {
        return 'has-text-danger'
      }
    },
    activeContacts() {
      return this.contacts.filter((c) => c.active)
    },
    activeCustomers() {
      return this.customers.filter((c) => c.active)
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.innerValue.isNew) {
        invoiceDate = new Date(`${this.innerValue.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    showAsset(newVal, oldVal) {
      if (!newVal.customer && !newVal.insurer) {
        this.assetFilter.showCustomer = !oldVal.customer
        this.assetFilter.showInsurer = !oldVal.insurer
      }
      this.getAssetDropdown()
    },
    selectedInvoiceDate: function (newVal, oldVal) {
      if (newVal) {
        this.innerValue.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.invoiceDate = null
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  created() {
    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
      this.onReload()
    })
    this.invoiceDate = new Date(`${this.innerValue.invoiceDate}`)
    this.selectedInvoiceDate = new Date(`${this.innerValue.invoiceDate}`)
    this.assetFilter.assetId = this.innerValue.assetId
    this.getAssetDropdown()
  },
  mounted() {
    this.$v.detailGroup.$touch()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  methods: {
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
           this.setReadOnlyView(!this.readOnlyView)
      }
    },
   canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    },
    update(key, value) {
      this.$emit(
        'input',
        _tap(_cloneDeep(this.local), (v) => _set(v, key, value))
      )
    },
    async getAssetDropdown() {
      this.isCustomerLoading = true
      ;[this.customers, this.contacts] = await Promise.all([
        QuickInvoiceService.getAssetDropdownProto(this.assetFilter),
        QuickInvoiceService.getContactDropdown(this.innerValue.assetId)
      ])
      if (this.assetFilter.assetId !== Guid.empty()) {
        this.selectedAsset = this.customers.find((c) => c.assetId === this.innerValue.assetId)
      }
      this.selectedContact = this.contacts.find((c) => c.contactId === this.innerValue.contactId)
      this.isCustomerLoading = false
    },
    getAssetDropdownFilter: _debounce(async function (query) {
      this.isCustomerLoading = true
      this.assetFilter.assetName = query
      this.customers = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      this.isCustomerLoading = false
    }, 500),
    async selectAsset(selected) {
      this.innerValue.assetId = selected.assetId
      this.innerValue.assetType = selected.assetType
      this.innerValue.invoiceGlCode = selected.assetGlCode
      this.assetFilter.assetId = selected.assetId
      this.innerValue.contactId = Guid.empty()
      this.contacts = await QuickInvoiceService.getContactDropdown(selected.assetId)
      this.selectedContact = null
    },
    removeAsset(removed) {
      this.assetFilter.assetId = Guid.empty()
      this.innerValue.assetId = Guid.empty()
      this.innerValue.contactId = Guid.empty()
      this.innerValue.assetType = null
      this.selectedContact = null
      this.contacts = []
      this.getAssetDropdown()
    },
    selectContact(selected) {
      this.innerValue.contactId = selected.contactId
    },
    removeContact(removed) {
      this.innerValue.contactId = Guid.empty()
    },
    onContactSearchChange(query) {
      this.isContactLoading = true
      if (query && this.selectedContact) {
        this.selectedContact = null
      }
      this.isContactLoading = false
    },
    captureContactInput(event) {
      this.contactInput = event.target.value
    },
    deleteRemark(remark, index, remarkType) {
      if (remark.isNew) {
        if (remarkType === this.remarkTypes.Remark) {
          this.innerValue.remarks.splice(index, 1)
        } else if (remarkType === this.remarkTypes.PrintedRemark) {
          this.innerValue.printedRemarks.splice(index, 1)
        }
      } else {
        remark.isDeleted = true
      }
    },
    addRemarks(remark, printedRemark) {
      if (remark) {
        const newRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.Remark, remark)
        this.innerValue.remarks.push(newRemark)
      }
      if (printedRemark) {
        const newPrintedRemark = new RemarkModel(this.innerValue.invoiceId, RemarkTypes.PrintedRemark, printedRemark)
        this.innerValue.printedRemarks.push(newPrintedRemark)
      }
      this.toggleRemarkModal()
    },
    toggleRemarkModal() {
      this.isRemarkModalActive = !this.isRemarkModalActive
    },
    onEditAddAsset() {
      if (this.gstPaid) {
        return false
      }
      this.assetButtonLoading = true
      if (this.hasAssetSelected) {
        if (this.selectedAsset.assetType === AssetTypes.Customer.toString()) {
          this.setCustomerReturnRoute(this.$route)
          // Edit customer
          this.editStoreCustomer(this.selectedAsset.assetId)
        } else if (this.selectedAsset.assetType === AssetTypes.Insurer.toString()) {
          this.setInsurerReturnRoute(this.$route)
          this.editStoreInsurer(this.selectedAsset.assetId)
        }
      } else {
        this.setCustomerReturnRoute(this.$route)
        this.addStoreCustomer(this.assetFilter.assetName)
      }
    },
    onEditAddContact(event) {
      if (!this.hasAssetSelected || this.gstPaid) {
        return false
      }
      this.contactButtonLoading = true
      this.setContactReturnRoute(this.$route)
      if (this.hasContactSelected) {
        this.editStoreContact(this.selectedContact.contactId)
      } else {
        const payload = new ContactPayload(this.contactInput, this.innerValue.assetId, this.innerValue.assetType, this.selectedAsset.assetName)
        this.addStoreContact(payload)
      }
    },
    onReload() {
      this.$nextTick(() => {
        this.assetFilter.assetId = this.innerValue.assetId
        this.getAssetDropdown()
      })
    },
    invoiceDateInputEvent(event) {
      this.selectedInvoiceDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
